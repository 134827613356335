import { gql } from '@apollo/client';

export const blacklistQuery = gql`
  query Blacklist {
    blacklist {
      success
      error {
        message
      }
      items {
        value
        isIp
      }
    }
  }
`;

export type BlacklistResponse = {
  blacklist: Success | Error;
};

export type BlacklistItem = {
  value?: string;
  isIp: boolean;
};

type Success = {
  success: true;
  items: BlacklistItem[];
};

type Error = {
  success: false;
  error: {
    message: string;
  };
};
