import { gql } from '@apollo/client';

export const getAppConfigQuery = gql`
  query GetAppConfig {
    appConfig {
      success
      error {
        message
      }
      appConfig {
        demoAvailability
        cookiesWarning {
          warningText
          linkText
        }
        registrationTypes
        gmtId
        ymId
        zendeskId
        googleSiteVerification
        clarityId
        title
        registrationAgreementText
        limits {
          depositMin
          withdrawalMin
          depositMax
          withdrawalMax
        }
        icons {
          icon {
            url
          }
          shortcut {
            url
          }
          apple {
            url
          }
          others {
            rel
            icon {
              url
            }
          }
          header {
            url
            alt
          }
        }
      }
    }
    categoryPlacement {
      success
      error {
        message
      }
      categoryPlacement {
        lobbyNavigationCategories {
          id
          slug
          name
          title
        }
        lobbyCategories {
          id
          slug
          name
          title
        }
        mainCategories {
          id
          slug
          name
          title
        }
        searchNavMenuCategory {
          title
          name
          slug
        }
        mainFavoritesPosition
        lobbyFavoritesPosition
      }
    }
  }
`;
