import { FC, useRef, useState } from 'react';
import { CountryType, getFlagIcon } from './countries';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { useClickOutside } from '@/shared/lib/hooks/use-click-outside';
import { CaretDownIcon } from '@/shared/ui';
import classNames from 'classnames';

type Props = {
  selectedCountry: CountryType;
  setSelectedCountry: (country: CountryType) => void;
  countries: CountryType[];
  focused?: boolean;
};

export const CountrySelect: FC<Props> = ({
  selectedCountry,
  setSelectedCountry,
  countries,
  focused
}) => {
  const [isCountryListOpen, setIsCountryListOpen] = useState(false);
  const [search, setSearch] = useState('');

  const realCountries = countries.filter((country) => country.iso !== '00');
  const ableToOpen = realCountries.length > 1;

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(search.toLowerCase())
  );

  const onSelectCountry = (country: CountryType) => {
    setSelectedCountry(country);
    setIsCountryListOpen(false);
  };

  const countryListRef = useRef<HTMLDivElement>(null);

  useClickOutside([countryListRef], (isOutside) => {
    if (isOutside) {
      setIsCountryListOpen(false);
    }
  });

  const onFlagClick = () => {
    if (!ableToOpen) return;
    setIsCountryListOpen(!isCountryListOpen);
  };

  return (
    <div>
      <div>
        <div
          className={classNames(
            'flex h-[40px] w-[60px] cursor-pointer select-none items-center justify-between gap-[8px] rounded-1 border border-divider-standard bg-input p-[8px] text-xl focus:border-divider-light group-hover:border-divider-light md:h-[54px] md:w-[78px] md:rounded-2 md:px-[16px] md:py-[15px] md:text-3xl',
            {
              '!border-divider-light': focused
            }
          )}
          onClick={onFlagClick}
        >
          <div className="flex h-[24px] w-[24px] flex-grow items-center justify-center">
            {realCountries.length === 1
              ? getFlagIcon(realCountries[0].iso)
              : getFlagIcon(selectedCountry?.iso)}
          </div>
          {ableToOpen && (
            <div className="flex w-2 flex-grow-0 items-center justify-center text-[#B2B3B7]">
              {isCountryListOpen ? (
                <CaretDownIcon className="h-[14px] w-[14px] rotate-180 transform" />
              ) : (
                <CaretDownIcon className="h-[14px] w-[14px]" />
              )}
            </div>
          )}
        </div>
        {isCountryListOpen && (
          <div
            className="absolute z-2 mt-1 max-h-[200px] overflow-y-auto rounded-md border border-solid border-grey bg-main-light_dark"
            ref={countryListRef}
          >
            <input
              type="text"
              placeholder="Search..."
              className="w-full border-none bg-main-light p-1 text-white focus:outline-none"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {filteredCountries.map((country) => {
              if (country.iso === '00') return null;
              return (
                <div
                  key={country.iso}
                  onClick={() => onSelectCountry(country)}
                  className="flex cursor-pointer select-none items-center p-1 hover:bg-main-light"
                >
                  <div>{getFlagIcon(country.iso)}</div>
                  <div className="ml-1 text-white">{country.name}</div>
                  <div className="ml-1 text-grey-light">{country.code}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
