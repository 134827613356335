'use client';
import { redirect } from 'next/navigation';
import { useProfile } from '@/entities/profile';
import { useEffect, useState } from 'react';
import { useAuth } from '@/entities/auth';
import { RoutesConfig } from '@/shared/routes/config';

const PAGES_WITHOUT_AUTH = new Set([RoutesConfig.login, RoutesConfig.recovery]);

export function useWithAuth({ skip }: { skip?: boolean } = {}) {
  const { isLoggedIn, ready } = useProfile();
  const { isAuth, ready: readyAuth } = useAuth();

  useEffect(() => {
    if (((readyAuth && !isAuth) || (readyAuth && isAuth && ready && !isLoggedIn)) && !skip) {
      redirect('/?login=true');
    }
  }, [isLoggedIn, ready, readyAuth, isAuth, skip]);
}

export function useWithoutAuth() {
  // This functionality will be removed in DNT-2561
  const { isLoggedIn, ready } = useProfile();
  let prevPagePath;
  if (typeof window !== 'undefined' && window.document) {
    prevPagePath = window.document.referrer;
  }
  const prevPageLocalPath = prevPagePath && '/' + prevPagePath.split('/').slice(3).join('/');
  useEffect(() => {
    if (isLoggedIn && ready) {
      redirect(
        prevPageLocalPath && !PAGES_WITHOUT_AUTH.has(prevPageLocalPath) ? prevPageLocalPath : '/'
      );
    }
  }, [isLoggedIn, prevPageLocalPath, ready]);
}

export function useRegistrationWithoutAuth() {
  const { isLoggedIn, ready } = useProfile();
  const [registrationInProgress, setRegistrationInProgress] = useState(false);

  useEffect(() => {
    if (isLoggedIn && ready && !registrationInProgress) {
      redirect('/');
    }
  }, [isLoggedIn, ready, registrationInProgress]);

  return { setRegistrationInProgress };
}
