import { blacklistQuery, BlacklistResponse } from './api';
import { useQuery } from '@apollo/client';

export const useBlacklist = () => {
  const { data, loading } = useQuery<BlacklistResponse>(blacklistQuery, {
    fetchPolicy: 'no-cache'
  });

  const items = data?.blacklist.success ? data?.blacklist.items : [];
  const ips: string[] = [];
  const countries: string[] = [];

  items.forEach((val) => (val.isIp ? ips.push(val.value || '') : countries.push(val.value || '')));

  return {
    loading: loading,
    ips,
    countries
  };
};
