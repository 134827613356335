import { useQuery } from '@apollo/client';

import { getAppConfigQuery } from './api';

type Category = {
  id: string;
  name: string;
  slug: string;
  title: string;
};

type RegistrationType = 'email' | 'phone';

type AppConfigResponse = {
  demoAvailability: 'always' | 'unauth' | 'never';
  cookiesWarning?: {
    linkText?: string;
    warningText?: string;
  };
  registrationTypes: RegistrationType[];
  limits?: {
    depositMin?: number | null;
    withdrawalMin?: number | null;
    depositMax?: number | null;
    withdrawalMax?: number | null;
  };
  registrationAgreementText?: string;
};

export type CategoryPlacementResponse = {
  lobbyNavigationCategories: Category[];
  lobbyCategories: Category[];
  mainCategories: Category[];
  mainFavoritesPosition: number | null;
  lobbyFavoritesPosition: number | null;
  searchNavMenuCategory: Category | null;
};

export type Config = AppConfigResponse & CategoryPlacementResponse & { success?: boolean };

type AppConfigModel = {
  config: Config;
  loading?: boolean;
};

export const DEFAULT_CONFIG: Config = {
  lobbyNavigationCategories: [],
  lobbyCategories: [],
  mainCategories: [],
  searchNavMenuCategory: null,
  mainFavoritesPosition: null,
  lobbyFavoritesPosition: null,
  demoAvailability: 'never',
  registrationAgreementText: '',
  cookiesWarning: {
    linkText: '',
    warningText: ''
  },
  registrationTypes: [],
  limits: {
    depositMin: null,
    withdrawalMin: null,
    depositMax: null,
    withdrawalMax: null
  }
};

export const useAppConfig = (): AppConfigModel => {
  const { data, loading } = useQuery<{
    appConfig: { success: boolean; error?: { message?: string }; appConfig: Config };
    categoryPlacement: {
      success: boolean;
      error?: { message?: string };
      categoryPlacement: CategoryPlacementResponse;
    };
  }>(getAppConfigQuery);

  return {
    config:
      data?.appConfig.success && data?.categoryPlacement.success
        ? {
            ...data.appConfig.appConfig,
            ...data.categoryPlacement.categoryPlacement,
            success: true
          }
        : DEFAULT_CONFIG,
    loading
  };
};
