import { ChangeEvent, FC, use, useEffect, useMemo, useRef, useState } from 'react';
import { CountrySelect } from './countrySelect';
import {
  CountryType,
  countries as defaultCountries,
  detectCountryByNumber,
  formatMaskToInputFormat,
  formatToMask,
  formatToNumeric,
  formatOnlyDigits
} from './countries';
import classNames from 'classnames';
import ReactInputMask from '@mona-health/react-input-mask';
import { CrossIcon } from '../icons';

type Props = {
  value?: string;
  onChange: (value: string) => void;
  label?: string;
  name?: string;
  errorMessage?: string;
  infoText?: string;
  onBlur?: () => void;
  onInputStart?: () => void;
  countryCode?: string;
  setCountryCode?: (arg: string) => void;
  disabled?: boolean;
  countries?: CountryType[];
  placeholder?: string;
};

export const CustomPhoneInput = ({
  value,
  onChange,
  onInputStart,
  name,
  label,
  errorMessage,
  infoText,
  onBlur,
  countryCode,
  setCountryCode,
  disabled,
  placeholder,
  countries = defaultCountries
}: Props) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryType>(
    countryCode ? detectCountryByNumber(countryCode) : countries[0]
  );
  const [hasStartedInput, setHasStartedInput] = useState(false);
  const inputContainerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!value) return;
    const valueWithPlus = value.startsWith('+') ? value : `+${value}`;
    selectCountry(valueWithPlus || '');
    onChange(valueWithPlus || '');
  }, []);

  const onSelectCountry = (country: CountryType, noFocus?: boolean) => {
    const mask = typeof country.mask === 'string' ? country.mask : country.mask[0];
    const formattedMask = formatMaskToInputFormat(mask);
    onChange(`${country.code}${formattedMask}`);
    setSelectedCountry(country);
    if (noFocus) return;
    setTimeout(() => {
      inputContainerRef.current?.querySelector('input')?.focus();
    }, 100);
  };

  const selectCountry = (value: string) => {
    const numeric = formatToNumeric(value);
    if (setCountryCode) {
      setCountryCode(numeric);
    }
    setSelectedCountry(detectCountryByNumber(numeric));
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!hasStartedInput && onInputStart && formatOnlyDigits(e.target.value)) {
      setHasStartedInput(true);
      onInputStart();
    }

    if (detectCountryByNumber(formatToNumeric(e.target.value)) !== selectedCountry) {
      setTimeout(() => {
        const value = inputContainerRef.current?.querySelector('input')?.value;
        if (value) {
          const position = value.indexOf('_');
          if (position !== -1) {
            inputContainerRef.current
              ?.querySelector('input')
              ?.setSelectionRange(position, position);
          }
        }
      }, 100);
    }
    selectCountry(e.target.value);
    onChange(e.target.value);
  };

  const onInputPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedValue = e.clipboardData.getData('text');
    const numeric = formatToNumeric(pastedValue);
    const valueWithPlus = numeric.startsWith('+') ? numeric : `+${numeric}`;

    selectCountry(valueWithPlus);
    setTimeout(() => {
      onChange(valueWithPlus || '');
    }, 100);
  };

  const mask = useMemo(() => {
    const left = formatToMask(selectedCountry.code);
    const right =
      typeof selectedCountry.mask === 'string' ? selectedCountry.mask : selectedCountry.mask[0];
    const rightFormatted = formatToMask(right);
    return `${left}${rightFormatted}`;
  }, [selectedCountry]);

  const [inputFocused, setInputFocused] = useState(false);
  const onInputFocus = () => setInputFocused(true);
  const onInputBlur = () => {
    setInputFocused(false);
    onBlur?.();
  };

  useEffect(() => {
    if (!value && countries.filter((country) => country.iso !== '00').length === 1) {
      setTimeout(() => {
        onSelectCountry(countries[1], true);
      }, 10);
    }
  }, []);

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="typography-body-regular mb-xxxs block text-white md:typography-title-regular md:mb-xxs"
        >
          {label}
        </label>
      )}
      <div
        className={classNames('group relative flex w-full gap-1', {
          'pointer-events-none': disabled
        })}
        ref={inputContainerRef}
      >
        <CountrySelect
          selectedCountry={selectedCountry}
          setSelectedCountry={onSelectCountry}
          countries={countries}
          focused={inputFocused || Boolean(value)}
        />
        {value !== '+' && value && (
          <div
            className={classNames(
              'absolute inset-y-0 right-0 flex cursor-pointer items-center px-2 md:px-3'
            )}
            onClick={() => onChange?.('+')}
          >
            <CrossIcon className="h-2 w-2 text-white/70 hover:text-white" />
          </div>
        )}
        <ReactInputMask
          mask={mask}
          placeholder={placeholder}
          name={name}
          className={classNames(
            'leading-tight h-[40px] w-full appearance-none rounded-xxs border border-divider-standard bg-input text-12 text-white focus:border-divider-light group-hover:border-divider-light md:h-[54px] md:rounded-s md:text-xl',
            {
              '!border-error focus:!border-divider-light': !!errorMessage,
              '!border-divider-light': inputFocused
            },
            { '!border-divider-light bg-input outline-none': Boolean(value) }
          )}
          value={value}
          defaultValue={value}
          maskPlaceholder="_"
          onChange={onInputChange}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          inputMode="tel"
          onPaste={onInputPaste}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === '_') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      </div>
      <div
        className={classNames(
          'mt-1/2 h-[20px] text-sm md:mt-1 md:h-[24px]',
          errorMessage ? 'text-error' : 'text-transparent'
        )}
      >
        {errorMessage && !inputFocused ? errorMessage : ' '}
      </div>
      {infoText && <div className="mt-1t/2 text-sm text-grey-light">{infoText}</div>}
    </div>
  );
};
